const DEVELOPMENT_API = 'https://api-dev.crowdthinc.net';

export const AppConfig = {
  // API_URL: 'http://18.220.244.147:9002', // stage
  // API_URL: 'http://31.202.123.239:4502', // http://192.168.88.89:9002', // dev
  // API_URL: 'http://31.202.123.239:4588', // 'http://192.168.88.89:8888',
  // API_URL: 'http://3.22.26.190:9002',
  // API_URL: 'https://api.crowdthinc.com' // prod
  API_URL: DEVELOPMENT_API,
};

export const Local = {
  TOKEN: 'act',
  REFRESH_TOKEN: 'rt',
  USER: 'ucr',
};
